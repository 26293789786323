<template>
    <div class="mx-5 sm:mx-16 md:mx-20 text-white sm:text-lg justify-items-center grid grid-cols-1 text-center gap-5 sm:gap-7">
        <img 
            src="../assets/images/wall-murals/text-image.jpg"
            class="rounded-lg shadow-xl mt-6 sm:mt-8 h-auto w-96"
        />
        <p>Our wall murals are crafted to enhance your living space with art and imagination, bringing your design to life.</p>
        <p>The murals mimic a painting’s effect on a wall. They are customizable in any pattern, scale and dimension.</p>
        <p>We produce the highest quality materials ensuring fire retardancy, washability, superior color fastness and </p>
        <img 
            src="../assets/images/wall-murals/15.jpg"
            class="rounded-lg shadow-xl h-auto lg:w-2/3"
        />
    </div>
</template>